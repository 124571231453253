import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflowX: 'hidden',
          overflowY: "overlay",
        }
      },
    },
    MuiModal: {
      //disableScrollLock: true,
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          fontWeight: 600,
          fontSize: "1.25rem",
          textTransform: "none",
          color: "#14183c",
        },
        textSecondary: {
          fontWeight: 400,
          textTransform: "none",
          fontSize: "1rem",
          color: "#14183c",
        },
      },
    },
  },
  palette: {
    background: {
      default: "#fdfcff",
      paper: "#f1f5f8",
    },
    primary: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#cccccc",
      contrastText: "#14183c",
    },
    secondary: {
      light: "#ffffff",
      main: "#f1f5f8",
      dark: "#bec2c5",
      contrastText: "#14183c",
    },
    text: {
      primary: "#14183c",
      secondary: "#ffffff",
    },
    error: {
      main: red.A400,
    },
    mode: "light",
  },
  typography: {
    fontFamily: ["Montserrat", "Open-Sans"].join(","),
    h6: {
      fontWeight: 400,
    },
    h5: {
      fontWeight: 400,
    },
    subtitle1: {
      fontWeight: 400,
    },
    h3: {
      fontWeight: 400,
    },
  },
});

export default theme;
