import React from "react";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CatalogueIcon from "@mui/icons-material/Apps";
import CurtainsIcon from "@mui/icons-material/Curtains";
import CallIcon from "@mui/icons-material/Call";
import InfoIcon from "@mui/icons-material/Info";
import FavouritesIcon from "@mui/icons-material/FavoriteBorder";
import StraightenIcon from "@mui/icons-material/Straighten";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import {
  Button,
  Divider,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { useWindowScroll, useWindowSize } from "react-use";
import SwipeableTemporaryDrawer from "./MenuDrawer";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    position: "relative",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    ...theme.typography.h6,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: "center",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

const CategoriesButton = ({ showDeviders }: { showDeviders: boolean }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        flex: "1",
        paddingLeft: theme.spacing(isLg ? 5 : 6),
        paddingRight: theme.spacing(isMd ? 0 : isLg ? 1 : 5),
      }}
    >
      {
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingRight: theme.spacing(isLg ? 4 : 10),
            }}
          >
            {isLg ? (
              <SwipeableTemporaryDrawer
                items={[
                  {
                    title: t("common.catalogue"),
                    icon: (
                      <CurtainsIcon
                        sx={{ color: theme.palette.primary.contrastText }}
                      />
                    ),
                    onClick: () =>
                      navigate("/", {
                        replace: true,
                        state: { scrollToCatalogue: true },
                      }),
                  },
                  {
                    title: t("common.aboutUs"),
                    icon: (
                      <InfoIcon
                        sx={{ color: theme.palette.primary.contrastText }}
                      />
                    ),
                    onClick: () =>
                      navigate("/about-us", {
                        replace: true,
                      }),
                  },
                  {
                    title: t("common.contacts"),
                    icon: (
                      <CallIcon
                        sx={{ color: theme.palette.primary.contrastText }}
                      />
                    ),
                    onClick: () =>
                      navigate("/about-us", {
                        replace: true,
                      }),
                  },
                  {
                    title: t("common.favorites"),
                    icon: (
                      <FavouritesIcon
                        sx={{ color: theme.palette.primary.contrastText }}
                      />
                    ),
                    onClick: console.log,
                  },
                  {
                    title: t("common.sizeGuide"),
                    icon: (
                      <StraightenIcon
                        sx={{ color: theme.palette.primary.contrastText }}
                      />
                    ),
                    onClick: console.log,
                  },
                ]}
              />
            ) : (
              <Button
                component={"h6"}
                variant={"text"}
                color={"primary"}
                startIcon={
                  <CatalogueIcon
                    sx={{ mr: 1, color: theme.palette.primary.contrastText }}
                  />
                }
                onClick={() =>
                  navigate("/", {
                    replace: true,
                    state: { scrollToCatalogue: true },
                  })
                }
              >
                {t("common.catalogue")}
              </Button>
            )}
          </Box>
          {!isLg && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                marginTop: `-${theme.spacing(3)}`,
                marginBottom: `-${theme.spacing(3)}`,
                opacity: showDeviders ? 1 : 0,
              }}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flex: "1",
              justifyContent: "space-around",
              alignItems: "center",
              paddingRight: theme.spacing(isLg ? 5 : 10),
              paddingLeft: theme.spacing(isLg ? 5 : 10),
            }}
          >
            {!isLg && (
              <>
                <Button
                  component={"h6"}
                  variant={"text"}
                  color={"secondary"}
                  onClick={() =>
                    navigate("/about-us", {
                      replace: true,
                    })
                  }
                >
                  {t("common.aboutUs")}
                </Button>
                <Button
                  component={"h6"}
                  variant={"text"}
                  color={"secondary"}
                  onClick={() =>
                    navigate("/about-us", {
                      replace: true,
                    })
                  }
                >
                  {t("common.contacts")}
                </Button>
              </>
            )}
            <IconButton
              edge="start"
              color={"primary"}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                navigate("/", {
                  replace: true,
                });
              }}
            >
              <CurtainsIcon
                sx={{
                  color: theme.palette.primary.contrastText,
                  fontSize: "3rem",
                }}
              />
            </IconButton>

            {!isLg && (
              <>
                <Button component={"h6"} variant={"text"} color={"secondary"}>
                  {t("common.favorites")}
                </Button>
                <Button component={"h6"} variant={"text"} color={"secondary"}>
                  {t("common.sizeGuide")}
                </Button>
              </>
            )}
          </Box>
          {!isLg && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                marginTop: `-${theme.spacing(3)}`,
                marginBottom: `-${theme.spacing(3)}`,
                opacity: showDeviders ? 1 : 0,
              }}
            />
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingLeft: theme.spacing(isLg ? 4 : 10),
            }}
          >
            <FormControl fullWidth>
              <Select
                value={"EN"}
                label="EN"
                onChange={console.log}
                input={<BootstrapInput />}
                MenuProps={{ disableScrollLock: true }}
              >
                <MenuItem value={"LV"}>LV</MenuItem>
                <MenuItem value={"RU"}>RU</MenuItem>
                <MenuItem value={"EN"}>EN</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </>
      }
    </Box>
  );
};

function MainToolbar({ isFixed = true }: { isFixed?: boolean }) {
  const theme = useTheme();
  const { y } = useWindowScroll();
  const { height } = useWindowSize();

  const isInitialPreviewScrolled = isFixed ? y > height / 10 : true;
  return (
    <>
      <AppBar
        elevation={isInitialPreviewScrolled ? 1 : 0}
        position={isFixed ? "fixed" : "relative"}
        sx={{
          zIndex: 99,
          transition: theme.transitions.create([
            "background-color",
            "border-color",
            "box-shadow",
            "opacity",
          ]),
          ...(!isInitialPreviewScrolled
            ? { backgroundColor: "rgb(0,0,0,0)" }
            : {}),
        }}
      >
        <StyledToolbar>
          <CategoriesButton showDeviders={isInitialPreviewScrolled} />
        </StyledToolbar>
      </AppBar>
    </>
  );
}

export default MainToolbar;
