import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Toolbar from "./Toolbar";
import Footer from "./Footer";

const AboutUs = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Toolbar isFixed={false} />
      <Grid container direction={isLg ? "column" : "row"}>
        <Grid item xs={6}>
          <iframe
            style={{
              ...(!isLg
                ? { height: "80vh", width: "100%" }
                : { width: "100%", height: "60vh" }),
              paddingLeft: theme.spacing(isMd ? 2 : isLg ? 3 : 4),
              paddingRight: theme.spacing(isMd ? 2 : isLg ? 3 : 4),
              paddingTop: theme.spacing(isMd ? 2 : isLg ? 3 : 4),
            }}
            title="Location"
            src="https://maps.google.com/maps?q=Diton%20nams&t=&z=15&ie=UTF8&iwloc=&output=embed"
            frameBorder={0}
            scrolling="no"
            marginHeight={0}
            marginWidth={0}
          />
        </Grid>
        <Grid item xs={6} sx={{ maxWidth: isLg ? "100% !important;" : null }}>
          <Box
            sx={{
              paddingTop: theme.spacing(isMd ? 2 : isLg ? 5 : 10),
              paddingLeft: theme.spacing(isMd ? 2 : isLg ? 5 : 10),
              paddingRight: theme.spacing(isMd ? 2 : isLg ? 5 : 10),
              paddingBottom: theme.spacing(isMd ? 2 : isLg ? 5 : 10),
            }}
          >
            <Typography variant="h4" marginBottom={theme.spacing(1)}>
              {t(`aboutUs.name`)}
            </Typography>
            <Typography variant="subtitle1">
              {t(`aboutUs.description`)}
            </Typography>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
              }}
            />
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {t(`aboutUs.address`)}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {t(`aboutUs.timeOpen`)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default AboutUs;
