import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";

function Footer() {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        height: "50px",
        marginTop: theme.spacing(isMd ? 0.5 : 3),
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Typography
        variant="subtitle1"
        color={theme.palette.text.disabled}
        marginRight={theme.spacing(isMd ? 2 : 4)}
      >
        © 2022 Daugavpils Aizskari
      </Typography>
    </Box>
  );
}

export default Footer;
