import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Image from "mui-image";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Divider,
  capitalize,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { flatten } from "lodash";
import { useTranslation } from "react-i18next";
import products from "../consts/products";
import Toolbar from "./Toolbar";
import Footer from "./Footer";

const SwiperContainer = styled(Box)(({ theme }) => ({
  zIndex: '1 !important;',
  "& .swiper-button-next": {
    color: theme.palette.primary.contrastText,
  },
  "& .swiper-button-prev": {
    color: theme.palette.primary.contrastText,
  },
  "& .swiper-pagination-bullet-active": {
    background: theme.palette.primary.contrastText,
  },
}));

const ProductPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const { productId } = useParams();
  const product = products.find(({ id }) => id === productId);
  console.log(JSON.stringify(product));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (productId && !product) {
      navigate("/", { replace: true });
    }
  }, [product, productId, navigate]);
  return (
    <>
      {!product ? null : (
        <>
          <Toolbar isFixed={false} />
          <Grid container direction={isLg ? "column" : "row"}>
            <Grid
              item
              xs={6}
              sx={{ maxWidth: isLg ? "100% !important;" : null }}
            >
              <SwiperContainer>
                <Swiper
                  navigation={true}
                  pagination={true}
                  keyboard={true}
                  modules={[Navigation, Pagination, Keyboard]}
                  className="mySwiper"
                >
                  {flatten(Object.values(product.imgs)).map((img, index) => (
                    <SwiperSlide key={`${product.id}-${index}`}>
                      <Image
                        fit={"contain"}
                        src={img}
                        bgColor={theme.palette.background.paper}
                        style={{
                          backgroundColor: theme.palette.background.paper,
                        }}
                        duration={1000}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperContainer>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ maxWidth: isLg ? "100% !important;" : null }}
            >
              <Box
                sx={{
                  paddingTop: theme.spacing(isMd ? 2 : isLg ? 5 : 10),
                  paddingLeft: theme.spacing(isMd ? 2 : isLg ? 5 : 10),
                  paddingRight: theme.spacing(isMd ? 2 : isLg ? 5 : 10),
                  paddingBottom: theme.spacing(isMd ? 2 : isLg ? 5 : 10),
                }}
              >
                <Typography variant="h4" marginRight={theme.spacing(1)}>
                  {product.title}
                </Typography>
                <Typography variant="subtitle1" marginRight={theme.spacing(1)}>
                  {product.price}
                </Typography>
                <Divider
                  orientation="horizontal"
                  flexItem
                  sx={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold" }}
                    marginRight={theme.spacing(1)}
                  >
                    {`${t(`catalog.color.color`)}:`}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold" }}
                    marginRight={theme.spacing(1)}
                  >
                    {capitalize(product.categories.color)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold" }}
                    marginRight={theme.spacing(1)}
                  >
                    {`${t(`catalog.light.lightFiltration`)}:`}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold" }}
                    marginRight={theme.spacing(1)}
                  >
                    {capitalize(t(`catalog.light.${product.categories.light}`))}
                  </Typography>
                </Box>
                <Divider
                  orientation="horizontal"
                  flexItem
                  sx={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                  }}
                />
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.disabled}
                  marginRight={theme.spacing(1)}
                >
                  {product.description}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Footer />
        </>
      )}
    </>
  );
};

export default ProductPage;
