import { Product } from "../types/Product";

const products: Product[] = [
  {
    id: "1",
    imgs: {
      full: "/images/Hannalill-full-xl.png",
      close: "/images/Hannalill-close-xl.jpeg",
      other: ["/images/Hannalill-full-xl.png", "/images/Hannalill-close-xl.jpeg"],
    },
    categories: {
      color: "white",
      light: "blackOut",
      sortBy: null,
    },
    title: "Hannalill",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu augue eget ante malesuada tempor. Sed at enim varius, aliquet erat ac, porttitor diam. Duis a mi eros. Pellentesque vitae dictum urna. Pellentesque diam leo, suscipit eu dictum ac, convallis sed enim. Nulla vel tellus et nulla tincidunt porta. Donec faucibus neque ac ante sodales, ac venenatis ex ullamcorper. Pellentesque sem sem, pellentesque at quam eu, pharetra pretium ex.",
    price: "24.99",
    createdAt: "2022-07-10T12:55:46",
  },
  {
    id: "2",
    imgs: {
      full: "/images/Hannalill-full-xl.png",
      close: "/images/Hannalill-close-xl.jpeg",
      other: ["/images/Hannalill-full-xl.png", "/images/Hannalill-close-xl.jpeg"],
    },
    categories: {
      color: "white",
      light: "blackOut",
      sortBy: null,
    },
    title: "Hilleborg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu augue eget ante malesuada tempor. Sed at enim varius, aliquet erat ac, porttitor diam. Duis a mi eros. Pellentesque vitae dictum urna. Pellentesque diam leo, suscipit eu dictum ac, convallis sed enim. Nulla vel tellus et nulla tincidunt porta. Donec faucibus neque ac ante sodales, ac venenatis ex ullamcorper. Pellentesque sem sem, pellentesque at quam eu, pharetra pretium ex.",
    price: "24.99",
    createdAt: "2022-07-10T12:55:46",
  },
  {
    id: "3",
    imgs: {
      full: "/images/Hannalill-full-xl.png",
      close: "/images/Hannalill-close-xl.jpeg",
      other: ["/images/Hannalill-full-xl.png", "/images/Hannalill-close-xl.jpeg"],
    },
    categories: {
      color: "white",
      light: "blackOut",
      sortBy: null,
    },
    title: "Dytag",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu augue eget ante malesuada tempor. Sed at enim varius, aliquet erat ac, porttitor diam. Duis a mi eros. Pellentesque vitae dictum urna. Pellentesque diam leo, suscipit eu dictum ac, convallis sed enim. Nulla vel tellus et nulla tincidunt porta. Donec faucibus neque ac ante sodales, ac venenatis ex ullamcorper. Pellentesque sem sem, pellentesque at quam eu, pharetra pretium ex.",
    price: "24.99",
    createdAt: "2022-07-10T12:55:46",
  },
  {
    id: "4",
    imgs: {
      full: "/images/Hannalill-full-xl.png",
      close: "/images/Hannalill-close-xl.jpeg",
      other: ["/images/Hannalill-full-xl.png", "/images/Hannalill-close-xl.jpeg"],
    },
    categories: {
      color: "white",
      light: "blackOut",
      sortBy: null,
    },
    title: "Hannalill",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu augue eget ante malesuada tempor. Sed at enim varius, aliquet erat ac, porttitor diam. Duis a mi eros. Pellentesque vitae dictum urna. Pellentesque diam leo, suscipit eu dictum ac, convallis sed enim. Nulla vel tellus et nulla tincidunt porta. Donec faucibus neque ac ante sodales, ac venenatis ex ullamcorper. Pellentesque sem sem, pellentesque at quam eu, pharetra pretium ex.",
    price: "24.99",
    createdAt: "2022-07-10T12:55:46",
  },
  {
    id: "5",
    imgs: {
      full: "/images/Hannalill-full-xl.png",
      close: "/images/Hannalill-close-xl.jpeg",
      other: ["/images/Hannalill-full-xl.png", "/images/Hannalill-close-xl.jpeg"],
    },
    categories: {
      color: "white",
      light: "blackOut",
      sortBy: null,
    },
    title: "Hilleborg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu augue eget ante malesuada tempor. Sed at enim varius, aliquet erat ac, porttitor diam. Duis a mi eros. Pellentesque vitae dictum urna. Pellentesque diam leo, suscipit eu dictum ac, convallis sed enim. Nulla vel tellus et nulla tincidunt porta. Donec faucibus neque ac ante sodales, ac venenatis ex ullamcorper. Pellentesque sem sem, pellentesque at quam eu, pharetra pretium ex.",
    price: "24.99",
    createdAt: "2022-07-10T12:55:46",
  },
  {
    id: "6",
    imgs: {
      full: "/images/Hannalill-full-xl.png",
      close: "/images/Hannalill-close-xl.jpeg",
      other: ["/images/Hannalill-full-xl.png", "/images/Hannalill-close-xl.jpeg"],
    },
    categories: {
      color: "white",
      light: "blackOut",
      sortBy: null,
    },
    title: "Dytag",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu augue eget ante malesuada tempor. Sed at enim varius, aliquet erat ac, porttitor diam. Duis a mi eros. Pellentesque vitae dictum urna. Pellentesque diam leo, suscipit eu dictum ac, convallis sed enim. Nulla vel tellus et nulla tincidunt porta. Donec faucibus neque ac ante sodales, ac venenatis ex ullamcorper. Pellentesque sem sem, pellentesque at quam eu, pharetra pretium ex.",
    price: "24.99",
    createdAt: "2022-07-10T12:55:46",
  },
  {
    id: "7",
    imgs: {
      full: "/images/Hannalill-full-xl.png",
      close: "/images/Hannalill-close-xl.jpeg",
      other: ["/images/Hannalill-full-xl.png", "/images/Hannalill-close-xl.jpeg"],
    },
    categories: {
      color: "white",
      light: "blackOut",
      sortBy: null,
    },
    title: "Hannalill",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu augue eget ante malesuada tempor. Sed at enim varius, aliquet erat ac, porttitor diam. Duis a mi eros. Pellentesque vitae dictum urna. Pellentesque diam leo, suscipit eu dictum ac, convallis sed enim. Nulla vel tellus et nulla tincidunt porta. Donec faucibus neque ac ante sodales, ac venenatis ex ullamcorper. Pellentesque sem sem, pellentesque at quam eu, pharetra pretium ex.",
    price: "24.99",
    createdAt: "2022-07-10T12:55:46",
  },
  {
    id: "8",
    imgs: {
      full: "/images/Hannalill-full-xl.png",
      close: "/images/Hannalill-close-xl.jpeg",
      other: ["/images/Hannalill-full-xl.png", "/images/Hannalill-close-xl.jpeg"],
    },
    categories: {
      color: "white",
      light: "blackOut",
      sortBy: null,
    },
    title: "Hilleborg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu augue eget ante malesuada tempor. Sed at enim varius, aliquet erat ac, porttitor diam. Duis a mi eros. Pellentesque vitae dictum urna. Pellentesque diam leo, suscipit eu dictum ac, convallis sed enim. Nulla vel tellus et nulla tincidunt porta. Donec faucibus neque ac ante sodales, ac venenatis ex ullamcorper. Pellentesque sem sem, pellentesque at quam eu, pharetra pretium ex.",
    price: "24.99",
    createdAt: "2022-07-10T12:55:46",
  },
  {
    id: "9",
    imgs: {
      full: "/images/Hannalill-full-xl.png",
      close: "/images/Hannalill-close-xl.jpeg",
      other: ["/images/Hannalill-full-xl.png", "/images/Hannalill-close-xl.jpeg"],
    },
    categories: {
      color: "white",
      light: "blackOut",
      sortBy: null,
    },
    title: "Dytag",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu augue eget ante malesuada tempor. Sed at enim varius, aliquet erat ac, porttitor diam. Duis a mi eros. Pellentesque vitae dictum urna. Pellentesque diam leo, suscipit eu dictum ac, convallis sed enim. Nulla vel tellus et nulla tincidunt porta. Donec faucibus neque ac ante sodales, ac venenatis ex ullamcorper. Pellentesque sem sem, pellentesque at quam eu, pharetra pretium ex.",
    price: "24.99",
    createdAt: "2022-07-10T12:55:46",
  },
];

export default products;