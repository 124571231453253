import * as React from "react";
import { useNavigate } from "react-router-dom";
import emotionStyled from "@emotion/styled";
import { styled, useTheme } from "@mui/material/styles";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Image from "mui-image";
import useMediaQuery from "@mui/material/useMediaQuery";
import products from "../consts/products";

const ImagesWithTransition = emotionStyled.div`
  position: relative;
  cursor: pointer;
`;

const MainImageWithHover = emotionStyled.div`
  &:hover {
    opacity: 0;
  }
`;

const CatalogItemItemBar = styled(ImageListItemBar)(({ theme }) => ({
  "& .MuiImageListItemBar-title ": {
    fontWeight: 600,
  },
  "& .MuiImageListItemBar-subtitle": {
    color: theme.palette.text.disabled,
    fontWeight: 600,
  },
}));

export default function TitlebarBelowImageList() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ImageList
      cols={isSm ? 1 : isMd ? 2 : 3}
      gap={isSm ? 10 : isMd ? 20 : 40}
      sx={{ overflow: "hidden" }}
    >
      {products.map((item) => (
        <ImageListItem key={item.id}>
          <ImagesWithTransition
            onClick={() => navigate(`/products/${item.id}`, { replace: true })}
          >
            <Image src={`${item.imgs.close}`} alt={item.title} duration={0} />
            <MainImageWithHover>
              <Image
                src={`${item.imgs.full}`}
                alt={item.title}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  backgroundColor: theme.palette.background.paper,
                }}
                duration={0}
              />
            </MainImageWithHover>
          </ImagesWithTransition>

          <CatalogItemItemBar
            title={item.title}
            subtitle={<span>{item.price} €</span>}
            position="below"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
