import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CirclePicker } from "react-color";
import TitlebarBelowImageList from "./ImageGrid";
import useMediaQuery from "@mui/material/useMediaQuery";

const CatalogForm = styled(FormControl)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  flex: 1,
  border: `1px solid ${theme.palette.divider}`,
  "& .MuiSelect-select": {
    padding: "0 !important",
  },
}));

const CatalogInput = styled(InputBase)(({ theme }) => ({
  "& .MuiFormControl-root": {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    backgroundColor: "red",
  },
  "& .MuiInputBase-input": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    ...theme.typography.subtitle1,
  },
  "& .MuiSelect-icon": {
    display: "none",
  },
}));

function Catalog(props: any, ref: any) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      ref={ref}
      sx={{
        paddingTop: theme.spacing(isSm ? 5 : isMd ? 7 : 10),
        paddingLeft: theme.spacing(isSm ? 2 : isMd ? 5 : 10),
        paddingRight: theme.spacing(isSm ? 2 : isMd ? 5 : 10),
        minWidth: "260px",
      }}
    >
      <Box
        flex={1}
        flexDirection={isMd ? "column" : "row"}
        display="flex"
        justifyContent={"space-between"}
        marginBottom={theme.spacing(isSm ? 1 : isMd ? 3 : 5)}
      >
        <CatalogForm
          sx={{
            marginRight: theme.spacing(isMd ? 0 : 5),
            marginBottom: theme.spacing(isMd ? 2 : 0),
            padding: theme.spacing(isSm ? 2 : isMd ? 2 : 3),
          }}
        >
          <Typography
            variant="subtitle1"
            color={theme.palette.text.disabled}
            marginRight={theme.spacing(1)}
          >
            {`${t("catalog.sort.sortBy")}:`}
          </Typography>
          <Select
            value={t("catalog.sort.popular")}
            input={<CatalogInput />}
          >
            <MenuItem value={t("catalog.sort.popular")}>
              {t("catalog.sort.popular")}
            </MenuItem>
            <MenuItem value={t("catalog.sort.latest")}>
              {t("catalog.sort.latest")}
            </MenuItem>
          </Select>
        </CatalogForm>
        <CatalogForm
          sx={{
            marginRight: theme.spacing(isMd ? 0 : 5),
            marginBottom: theme.spacing(isMd ? 2 : 0),
            padding: theme.spacing(isSm ? 2 : isMd ? 2 : 3),
          }}
        >
          <Typography
            variant="subtitle1"
            color={theme.palette.text.disabled}
            marginRight={theme.spacing(1)}
          >
            {`${t("catalog.light.lightFiltration")}:`}
          </Typography>
          <Select
            value={t("catalog.light.lightFiltering")}
            input={<CatalogInput />}
          >
            <MenuItem value={t("catalog.light.lightFiltering")}>
              {t("catalog.light.lightFiltering")}
            </MenuItem>
            <MenuItem value={t("catalog.light.roomDarkening")}>
              {t("catalog.light.roomDarkening")}
            </MenuItem>
            <MenuItem value={t("catalog.light.blackOut")}>
              {t("catalog.light.blackOut")}
            </MenuItem>
            <MenuItem value={t("catalog.light.sheerFabric")}>
              {t("catalog.light.sheerFabric")}
            </MenuItem>
          </Select>
        </CatalogForm>
        <CatalogForm
          sx={{
            padding: theme.spacing(isSm ? 2 : isMd ? 2 : 3),
          }}
        >
          <Typography
            variant="subtitle1"
            color={theme.palette.text.disabled}
            marginRight={theme.spacing(1)}
          >
            {`${t("catalog.color.color")}:`}
          </Typography>
          <Select
            value={t("catalog.color.any")}
            input={<CatalogInput />}
          >
            <MenuItem value={t("catalog.color.any")}>
              <CirclePicker />
            </MenuItem>
            <MenuItem value={t("catalog.color.any")}>
              {t("catalog.color.any")}
            </MenuItem>
          </Select>
        </CatalogForm>
      </Box>

      <Box flex={1}>
        <TitlebarBelowImageList />
      </Box>
    </Box>
  );
}

export default React.forwardRef(Catalog);
