import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Image from "mui-image";
import useMediaQuery from "@mui/material/useMediaQuery";
import Toolbar from "./Toolbar";
import Catalog from "./Catalog";
import Footer from "./Footer";

function Landing() {
  const theme = useTheme();
  const location = useLocation();
  const catalogRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    // @ts-ignore
    if (location?.state?.scrollToCatalogue && catalogRef.current) {
      window.history.replaceState({}, document.title);
      const elementPosition = catalogRef.current.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - 70;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
    }
  }, [location.state, catalogRef]);
  return (
    <>
      <Box height="100vh" display="flex" flexDirection="column">
        <Toolbar />
        <Box sx={{ paddingBottom: theme.spacing(10) }}>
          <div
            style={{
              position: "absolute",
              top: "0",
              bottom: "0",
              width: "100%",
              height: "100%",
              overflow: "hidden",
              zIndex: "-1",
            }}
          >
            <Image
              src={"images/bg-4.jpg"}
              style={
                {
                  //paddingLeft: 'calc(100vw - 100%)'
                  // minWidth: "100%",
                  // minHeight: "100%",
                  // width: "auto",
                  // height: "auto",
                  // position: "absolute",
                  // top: "65%",
                  // left: "50%",
                  // transform: "translate(-50%,-50%)",
                }
              }
              duration={1000}
              shift="bottom"
              distance="2rem"
              shiftDuration={320}
            />
            {/* <video
              style={{
                minWidth: "100%",
                minHeight: "100%",
                width: "auto",
                height: "auto",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
              autoPlay
              loop
              muted
              playsInline
            >
              <source src="videos/bg.mp4" type="video/mp4" />
            </video> */}
          </div>
          <Box
            sx={{
              marginLeft: theme.spacing(10),
              marginRight: theme.spacing(10),
              marginTop: theme.spacing(22),
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {/* <Typography variant="h3" maxWidth={"500px"}>
              {t("marketing.shortDescription")}
            </Typography> */}
          </Box>
        </Box>
      </Box>
      <Catalog ref={catalogRef} />
      <Footer />
    </>
  );
}

export default Landing;
